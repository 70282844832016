import { IGenericError } from '@libs/entity-lib';
import { createReducer, on } from '@ngrx/store';
import * as fromLessorMissingInfo from './timezone-info.actions';

export interface State {
  loading: boolean;
  error: IGenericError | null;
  timezone: string;
  localDateTime: string | null;
  timezones: string[];
}

export const initialState: State = {
  loading: false,
  error: null,
  timezone: '',
  localDateTime: null,
  timezones: []
};

export const reducer = createReducer(
  initialState,
  on(fromLessorMissingInfo.fetchTimezones, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(fromLessorMissingInfo.fetchTimezonesSuccefully, (state, { timezones }) => 
  ({
    ...state,
    loading: false,
    timezones
  })
  ),
  on(fromLessorMissingInfo.fetchTimezonesFailed, (state, { error }) => ({
    ...state,
    error,
    completed: true,
    loading: false
  })),

  on(fromLessorMissingInfo.fetchCurrentTimezone, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(fromLessorMissingInfo.fetchCurrentTimezoneSuccefully, (state, { timezone, localDateTime }) => 
  ({
    ...state,
    loading: false,
    timezone,
    localDateTime
  })
  ),
  on(fromLessorMissingInfo.fetchCurrentTimezoneFailed, (state, { error }) => ({
    ...state,
    error,
    completed: true,
    loading: false
  })),

  on(fromLessorMissingInfo.updateTimezone, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(fromLessorMissingInfo.updateTimezoneSuccefully, (state, { timezone, localDateTime }) => 
  ({
    ...state,
    loading: false,
    timezone,
    localDateTime
  })
  ),
  on(fromLessorMissingInfo.updateTimezoneFailed, (state, { error }) => ({
    ...state,
    error,
    completed: true,
    loading: false
  }))
);
