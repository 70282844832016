import { ActionReducer, ActionReducerMap } from '@ngrx/store';

import { MetaReducer } from '@ngrx/store';
import { lessorEnvironment } from '@libs/shared/util-environments';
import { RouterStateUrl, SettingsStateKeys } from '@libs/entity-lib';

import * as fromSettingsSocialSport from '@libs/settings-state';
import * as fromRouter from '@ngrx/router-store';
import * as fromCrudSportCenterInfo from '@libs/crud-lessor-lib';

import * as fromCourts from './courts/courts.reducer';
import * as fromEquipment from './equipment/equipment.reducer';
import * as fromPackage from './package/package.reducer';
import * as fromOpeningHour from './opening-hours/opening-hours.reducer';
import * as fromUtil from './util/util.reducer';
import * as fromScheduler from './scheduler/scheduler.reducer';
import * as fromSportCenter from './sport-center/sport-center.reducer';
import * as fromCancellationRole from './cancellation-role/cancellation-role.reducer';
import * as fromCustomers from './customer/customer.reducer';
import * as fromSocialMedia from './social-media/social-media.reducers';
import * as fromArenaInfo from './visitor-guide/visitor-guide.reducer';
import * as fromPolicyInfo from './policy/policy-info.reducer';
import * as fromMissingInfo from './missing-info/missing-info.reducer';
import * as fromBlockPeriod from './block-period/block-period.reducer';
import * as fromUnusualPeriod from './unusual-period/unusual-period.reducer';
import * as fromTimezone from './timezone/timezone-info.reducer';
import { localStorageSync } from 'ngrx-store-localstorage';

export interface State {
  routerReducer: fromRouter.RouterReducerState<RouterStateUrl>;
  settingsSocialSport: fromSettingsSocialSport.State;
  crudLessorSocialSport: fromCrudSportCenterInfo.State;
  [SettingsStateKeys.COURTS]: fromCourts.State;
  equipments: fromEquipment.State;
  openingHours: fromOpeningHour.State;
  util: fromUtil.State;
  scheduler: fromScheduler.State;
  sportCenter: fromSportCenter.State;
  cancellationRole: fromCancellationRole.State,
  customers: fromCustomers.State,
  [SettingsStateKeys.PACKAGES]: fromPackage.State,
  [SettingsStateKeys.SOCIAL_MEDIA]: fromSocialMedia.State,
  [SettingsStateKeys.VISITOR_GUIDE]: fromArenaInfo.State,
  [SettingsStateKeys.POLICY_INFO]: fromPolicyInfo.State,
  [SettingsStateKeys.LESSOR_MISSING_INFO]: fromMissingInfo.State,
  [SettingsStateKeys.BLOCK_PERIOD]: fromBlockPeriod.State,
  [SettingsStateKeys.UNUSUAL_PERIOD]: fromUnusualPeriod.State,
  [SettingsStateKeys.TIMEZONE]: fromTimezone.State,
}

export const reducers: ActionReducerMap<State> = {
  routerReducer: fromRouter.routerReducer,
  settingsSocialSport: fromSettingsSocialSport.reducerSettings,
  crudLessorSocialSport: fromCrudSportCenterInfo.reducer,
  [SettingsStateKeys.COURTS]: fromCourts.reducer,
  equipments: fromEquipment.reducer,
  openingHours: fromOpeningHour.reducer,
  util: fromUtil.reducer,
  scheduler: fromScheduler.reducer,
  sportCenter: fromSportCenter.reducer,
  cancellationRole: fromCancellationRole.reducer,
  customers: fromCustomers.reducer,
  [SettingsStateKeys.PACKAGES]: fromPackage.reducer,
  [SettingsStateKeys.SOCIAL_MEDIA]: fromSocialMedia.reducer,
  [SettingsStateKeys.VISITOR_GUIDE]: fromArenaInfo.reducer,
  [SettingsStateKeys.POLICY_INFO]: fromPolicyInfo.reducer,
  [SettingsStateKeys.LESSOR_MISSING_INFO]: fromMissingInfo.reducer,
  [SettingsStateKeys.BLOCK_PERIOD]: fromBlockPeriod.reducer,
  [SettingsStateKeys.UNUSUAL_PERIOD]: fromUnusualPeriod.reducer,
  [SettingsStateKeys.TIMEZONE]: fromTimezone.reducer,
};

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      SettingsStateKeys.CRUD_LESSOR_SOCIAL_SPORT,
      SettingsStateKeys.SETTINGS_SOCIAL_SPORT,
      SettingsStateKeys.AUTH_INFO,
      SettingsStateKeys.PROFILE_LESSOR,
      SettingsStateKeys.PACKAGES
    ],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: MetaReducer<State>[] = !lessorEnvironment.production
  ? [localStorageSyncReducer]
  : [localStorageSyncReducer];
